import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "../../schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["atticType"],
    properties: {
      atticType: {
        title: "Attic Type",
        ...props.atticType,
      },
    },
  };
}

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 1,
    maxWidth: "800px",
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    atticType: {
      "ui:widget": "RadioImages",
      "ui:autocomplete": "off",
    },
  };
}