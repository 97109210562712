import React from 'react';
import { Typography, useTheme } from "@mui/material";
import { getLanguage } from "@library/theme/multitenancy";

import { SlideComponent } from '../../index';

export const Title = ({ stepName } : SlideComponent ) => {
  const theme = useTheme();
  if (!theme.config.language[getLanguage(theme)].step![stepName]!.title) return null;
  return (
    <Typography variant="h1" sx={{ width: ["100%", "100%"], maxWidth: "900px", display: "inline-block", fontWeight: "bold", color: theme.palette.primary.contrastText, marginTop: 8 }}>
      {theme.config.language[getLanguage(theme)].step![stepName]!.title}
    </Typography>
  );
};

export const Header = () => {
  const theme = useTheme();
  if (theme.config?.Landing) {
    const Landing = theme.config.Landing;
    return <Landing />;
  }
}