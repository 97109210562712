export const GetStartedStepQuote = {
  id: "abcdefg1234567",
  user: {
    firstName: "Jane",
    lastName: "Doe",
  },
  Estimate: [
    {
      id: "clvfdpffi0028uid2h46607ce",
      quoteId: "clve8iswo0000uid2kv6h0a1y",
      tier: "replace",
      final: false,
      data: {
        eer: null,
        hspf: null,
        seer: null,
        sound: "",
        demoCost: 1080,
        netPrice: 11356,
        warranty: "",
        otherCost: null,
        muniRebate: null,
        wiringCost: 0,
        indoorUnit1: "96% AFUE Two-Stage Furnace",
        indoorUnit2: "",
        indoorUnit3: "",
        indoorUnit4: "",
        indoorUnit5: "",
        indoorUnit6: "",
        indoorUnit7: "",
        indoorUnit8: "",
        indoorUnit9: "",
        pearlRebate: 0,
        stateRebate: 0,
        ductworkCost: null,
        indoorUnit10: "",
        indoorUnit11: "",
        indoorUnit12: "",
        indoorUnit13: "",
        indoorUnit14: "",
        indoorUnit15: "",
        indoorUnit16: "",
        indoorUnit17: "",
        indoorUnit18: "",
        indoorUnit19: "",
        indoorUnit20: "",
        outdoorUnit1: "",
        outdoorUnit2: "",
        outdoorUnit3: "",
        outdoorUnit4: "",
        outdoorUnit5: "",
        rebatesTotal: 0,
        federalRebate: 0,
        hvacCostTotal: 10530,
        newSystemType:
          "Electric Resistive,Boiler,Furnace,Heat Pump / Mini-Split",
        timeToInstall: "2",
        utilityRebate: 0,
        airSealingCost: null,
        equipmentBrand: "Amana",
        insulationCost: null,
        muniRebateName: "",
        otherCostTotal: 0,
        permittingCost: 135,
        recommendation:
          "Based on the information you shared about your home and goals, we recommend a 2-ton ducted system.",
        systemCapacity: "24,000",
        electricianCost: 0,
        incentivesTotal: 0,
        newDistribution: "",
        pearlRebateName: "",
        remediationCost: null,
        stateRebateName: "",
        dimensionsIndoor: "",
        servicePanelCost: null,
        upFrontCostTotal: 11356,
        dimensionsOutdoor: "",
        federalRebateName: "",
        utilityRebateName: "",
        billImpactEstimate: -200,
        codeComplianceCost: null,
        installedCostTotal: 11356,
        balanceofSystemCost: 0,
        currentDistribution: "",
        currentSystemTypeAC:
          'Central Air Conditioner,Window-Unit Air Conditioner,Evaporative Cooling,Heat Pump / "Mini-Split"',
        electricalCostTotal: 0,
        indoorEquipmentCost: 6750,
        outdoorEquipmentCost: 0,
        billImpactExplanation:
          "Based on similar homes in Ann Arbor, we estimate that your bills will decrease by about $200 annually, with higher bills in the winter and lower bills in the summer. Note: This estimate is dependent on whether, energy prices, and your energy usage.",
        climateImpactEstimate: -1.3,
        currentSystemTypeHeat:
          "Electric Resistive,Boiler,Furnace,Heat Pump / Mini-Split",
        otherWeatherizationCost: null,
        weatherizationCostTotal: 826,
        climateImpactExplanation:
          "Based on similar homes in Ann Arbor, we estimate that you will reduce your carbon footprint by at least 1.3 MT annually, with climate benefits increasing across the life of your system.",
        distributorInstallerCost: 2565,
        ductSealingInsulationCost: 826,
        recommendationExplanation:
          "We've designed this system as a \"like for like\" replacement of your existing furnace. Your new system will function exactly like your current system - but it's more efficient. You can count on reliably lower utility bills, regardless of the season. However, you're signing up for another 20 years of fossil fuels!",
        equipmentBrandRecommendation:
          "We work with a number of top-tier brands - including Lennox, Carrier, Trane, American Standard, and Amana. All of these manufacturers sell a great product - we will work with you to determine the right option, based on equipment availability.",
      },
      estimatedTimeToInstall: null,
      upFrontCostPrice: null,
      photoUrl: null,
      totalCost: null,
      installedCost: null,
      upFrontCost: null,
      monthlyBill: null,
      climateImpact: null,
      timeToInstall: null,
      infoMD: null,
      images: [],
      createdAt: "2024-04-25T15:08:32.478Z",
      updatedAt: "2024-04-25T15:08:32.478Z",
    },
    {
      id: "clvfdpfg90029uid24jj12kh3",
      quoteId: "clve8iswo0000uid2kv6h0a1y",
      tier: "base",
      final: false,
      data: {
        eer: 12.5,
        hspf: 8.6,
        seer: 17.2,
        sound: "56-68 db (30-40% quieter)",
        demoCost: 1080,
        netPrice: 11705,
        warranty:
          "10 year manufacturer’s warranty (product registration is required)",
        otherCost: null,
        muniRebate: null,
        wiringCost: 473,
        indoorUnit1: "",
        indoorUnit2: "Amana S-Series 18,000 BTU Heat Pump Indoor Unit",
        indoorUnit3: "Amana S-Series 7,000 BTU Heat Pump Indoor Unit",
        indoorUnit4: "Amana S-Series 7,000 BTU Heat Pump Indoor Unit",
        indoorUnit5: "Amana S-Series 7,000 BTU Heat Pump Indoor Unit",
        indoorUnit6: "Amana S-Series 7,000 BTU Heat Pump Indoor Unit",
        indoorUnit7: "Amana S-Series 7,000 BTU Heat Pump Indoor Unit",
        indoorUnit8: "",
        indoorUnit9: "",
        pearlRebate: 10500,
        stateRebate: 0,
        ductworkCost: null,
        indoorUnit10: "",
        indoorUnit11: "",
        indoorUnit12: "",
        indoorUnit13: "",
        indoorUnit14: "",
        indoorUnit15: "",
        indoorUnit16: "",
        indoorUnit17: "",
        indoorUnit18: "",
        indoorUnit19: "",
        indoorUnit20: "",
        outdoorUnit1: "Amana S-Series 42,000 BTU Heat Pump Outdoor Unit",
        outdoorUnit2: "",
        outdoorUnit3: "",
        outdoorUnit4: "",
        outdoorUnit5: "",
        rebatesTotal: 10500,
        federalRebate: 0,
        hvacCostTotal: 22788,
        newSystemType: "Heat Pump / Mini-Split",
        timeToInstall: "2",
        utilityRebate: 0,
        airSealingCost: null,
        equipmentBrand: "Amana",
        insulationCost: null,
        muniRebateName: "",
        otherCostTotal: 0,
        permittingCost: 135,
        recommendation:
          "Based on the information you shared about your home and goals, we recommend a 4-ton ductless system.",
        systemCapacity: "42,000",
        electricianCost: 945,
        incentivesTotal: 2000,
        newDistribution: "Mini-Split",
        pearlRebateName: "",
        remediationCost: null,
        stateRebateName: "",
        dimensionsIndoor: "37-3/8 x 33 x 13-3/8",
        servicePanelCost: null,
        upFrontCostTotal: 13705,
        dimensionsOutdoor: "19-5/8 × 46-1/8 × 21-5/8",
        federalRebateName: "",
        utilityRebateName: "",
        billImpactEstimate: -100,
        codeComplianceCost: null,
        installedCostTotal: 24205,
        balanceofSystemCost: 5393,
        currentDistribution: "",
        currentSystemTypeAC:
          'Central Air Conditioner,Window-Unit Air Conditioner,Evaporative Cooling,Heat Pump / "Mini-Split"',
        electricalCostTotal: 1418,
        indoorEquipmentCost: 2005,
        outdoorEquipmentCost: 2295,
        billImpactExplanation:
          "Based on similar homes in Ann Arbor, we estimate that your bills will decrease by about $100 annually, with higher bills in the winter and lower bills in the summer. Note: This estimate is dependent on whether, energy prices, and your energy usage.",
        climateImpactEstimate: -1.8,
        currentSystemTypeHeat:
          "Electric Resistive,Boiler,Furnace,Heat Pump / Mini-Split",
        otherWeatherizationCost: null,
        weatherizationCostTotal: 0,
        climateImpactExplanation:
          "Based on similar homes in Ann Arbor, we estimate that you will reduce your carbon footprint by at least 1.8 MT annually, with climate benefits increasing across the life of your system.",
        distributorInstallerCost: 11880,
        ductSealingInsulationCost: null,
        recommendationExplanation:
          "We've designed this system to provide great comfort at low cost. Your system has been sized to the full heating load of your home. However, we recommend leaving your existing system in place to provide backup heat on very cold days if it's functional, as this will result in lower utility bills. If your current system fails, we recommend replacing it with another source of backup heat. During the summer, this system will provide efficient air conditioning.",
        equipmentBrandRecommendation:
          "We recommend Amana, because they deliver superior performance and reliability - but if you have a brand preference, we’ll work with you.",
      },
      estimatedTimeToInstall: null,
      upFrontCostPrice: null,
      photoUrl: null,
      totalCost: null,
      installedCost: null,
      upFrontCost: null,
      monthlyBill: null,
      climateImpact: null,
      timeToInstall: null,
      infoMD: null,
      images: [],
      createdAt: "2024-04-25T15:08:32.505Z",
      updatedAt: "2024-04-25T15:08:32.505Z",
    },
    {
      id: "clvfdpfgw002auid2is3lo4rd",
      quoteId: "clve8iswo0000uid2kv6h0a1y",
      tier: "pearl",
      final: false,
      data: {
        eer: 12.5,
        hspf: 8.6,
        seer: 17.2,
        sound: "56-68 db (30-40% quieter)",
        demoCost: 1080,
        netPrice: 11563,
        warranty:
          "10 year manufacturer’s warranty (product registration is required)",
        otherCost: null,
        muniRebate: null,
        wiringCost: 473,
        indoorUnit1: "",
        indoorUnit2: "Amana S-Series 18,000 BTU Heat Pump Indoor Unit",
        indoorUnit3: "Amana S-Series 7,000 BTU Heat Pump Indoor Unit",
        indoorUnit4: "Amana S-Series 7,000 BTU Heat Pump Indoor Unit",
        indoorUnit5: "Amana S-Series 7,000 BTU Heat Pump Indoor Unit",
        indoorUnit6: "Amana S-Series 7,000 BTU Heat Pump Indoor Unit",
        indoorUnit7: "Amana S-Series 7,000 BTU Heat Pump Indoor Unit",
        indoorUnit8: "",
        indoorUnit9: "",
        pearlRebate: 12100,
        stateRebate: 0,
        ductworkCost: null,
        indoorUnit10: "",
        indoorUnit11: "",
        indoorUnit12: "",
        indoorUnit13: "",
        indoorUnit14: "",
        indoorUnit15: "",
        indoorUnit16: "",
        indoorUnit17: "",
        indoorUnit18: "",
        indoorUnit19: "",
        indoorUnit20: "",
        outdoorUnit1: "Amana S-Series 42,000 BTU Heat Pump Outdoor Unit",
        outdoorUnit2: "",
        outdoorUnit3: "",
        outdoorUnit4: "",
        outdoorUnit5: "",
        rebatesTotal: 12100,
        federalRebate: 0,
        hvacCostTotal: 22788,
        newSystemType: "Heat Pump / Mini-Split",
        timeToInstall: "2",
        utilityRebate: 0,
        airSealingCost: 1458,
        equipmentBrand: "Amana",
        insulationCost: null,
        muniRebateName: "",
        otherCostTotal: 0,
        permittingCost: 135,
        recommendation:
          "Based on the information you shared about your home and goals, we recommend a 4-ton ductless system.",
        systemCapacity: "42,000",
        electricianCost: 945,
        incentivesTotal: 2000,
        newDistribution: "Mini-Split",
        pearlRebateName: "",
        remediationCost: null,
        stateRebateName: "",
        dimensionsIndoor: "37-3/8 x 33 x 13-3/8",
        servicePanelCost: null,
        upFrontCostTotal: 13563,
        dimensionsOutdoor: "19-5/8 × 46-1/8 × 21-5/8",
        federalRebateName: "",
        utilityRebateName: "",
        billImpactEstimate: -200,
        codeComplianceCost: null,
        installedCostTotal: 25663,
        balanceofSystemCost: 5393,
        currentDistribution: "",
        currentSystemTypeAC:
          'Central Air Conditioner,Window-Unit Air Conditioner,Evaporative Cooling,Heat Pump / "Mini-Split"',
        electricalCostTotal: 1418,
        indoorEquipmentCost: 2005,
        outdoorEquipmentCost: 2295,
        billImpactExplanation:
          "Based on similar homes in Ann Arbor, we estimate that your bills will decrease by about $200 annually, with higher bills in the winter and lower bills in the summer. Note: This estimate is dependent on whether, energy prices, and your energy usage.",
        climateImpactEstimate: -2.6,
        currentSystemTypeHeat:
          "Electric Resistive,Boiler,Furnace,Heat Pump / Mini-Split",
        otherWeatherizationCost: null,
        weatherizationCostTotal: 1458,
        climateImpactExplanation:
          "Based on similar homes in Ann Arbor, we estimate that you will reduce your carbon footprint by at least 2.6 MT annually, with climate benefits increasing across the life of your system.",
        distributorInstallerCost: 11880,
        ductSealingInsulationCost: null,
        recommendationExplanation:
          "We've designed this system to maximize your savings! Your new system includes an electric heat pump that is sized to the full heating load of your home and a brand new high-efficiency natural gas system to provide backup heat on very cold days, as this will result in lower utility bills. During the summer, this system will provide efficient air conditioning.",
        equipmentBrandRecommendation:
          "We recommend Amana, because they deliver superior performance and reliability - and because their air-source heat pump will pair with your Amana backup furnace. But, if you have a brand preference, we’ll work with you.",
      },
      estimatedTimeToInstall: null,
      upFrontCostPrice: null,
      photoUrl: null,
      totalCost: null,
      installedCost: null,
      upFrontCost: null,
      monthlyBill: null,
      climateImpact: null,
      timeToInstall: null,
      infoMD: null,
      images: [],
      createdAt: "2024-04-25T15:08:32.529Z",
      updatedAt: "2024-04-25T15:08:32.529Z",
    },
  ],
};
