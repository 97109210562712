import { createContext } from "react";

import type { TenantName } from "./multitenancy";
import { isAvailableTenant } from "./multitenancy";

interface ChannelContext {
  channelId: string;
  channelTheme: TenantName;
  setChannelId: (newValue: TenantName) => void;
}

const DEFAULT_CHANNEL_THEME = "pearl";
let CHANNEL = "pearl";

// @TODO This should be moved into the database
let CHANNEL_THEME = DEFAULT_CHANNEL_THEME as TenantName;
if (typeof window !== "undefined") {
  if (window?.location?.href?.includes("dte.")) {
    CHANNEL_THEME = "dte" as TenantName;
    CHANNEL = "dte";
  }

  const params = new URL(window?.document?.location?.toString()).searchParams;
  let channel = params.get("channel");
  if (channel) {
    if (isAvailableTenant(channel)) {
      CHANNEL_THEME = channel as TenantName;
    }
    CHANNEL = channel;
  } else {
    channel = localStorage.getItem("channel");
    if (channel) {
      if (isAvailableTenant(channel)) {
        CHANNEL_THEME = channel as TenantName;
      }
      CHANNEL = channel;
    }
  }

  window.localStorage.setItem("channel", CHANNEL);
}

export const ChannelContext = createContext<ChannelContext>({
  channelId: CHANNEL,
  channelTheme: CHANNEL_THEME,
  setChannelId: () => {},
});
