import StaticComponent from "./StaticComponent";


export const schema = () => {
  return {
    type: "object",
    properties: {
      user: {
        type: "object",
        properties: {
          address: {
            title: "Address",
            type: "string",
            readOnly: true,
          },
          lat: {
            type: "number",
          },
          lng: {
            type: "number"
          }
        },
      },
    },
  };
}

export const uiSchema = () => {
  return {
    user: {
      "ui:ObjectFieldTemplate": StaticComponent,
      "ui:options": {
        label: false,
      },
    },
  };
}