import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "../../schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["upgrades"],
    properties: {
      upgrades: {
        ...props.upgrades,
      },
    },
    dependencies: {
      upgrades: {
        oneOf: [
          {
            properties: {
              upgrades: {
                ...props.upgrades,
                contains: { const: "something_else" },
              },
              upgradesMoreInfo: {
                ...props.upgradesMoreInfo,
              },
            },
          },
        ],
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 3,
    maxWidth: "700px",
    spacing: 2,
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    upgrades: {
      "ui:widget": "CheckboxImages",
      "ui:autocomplete": "off",
      xs: 6,
      sm: 6,
      md: 6,
      lg: 6,
    },
    upgradesMoreInfo: {
      "ui:autocomplete": "off",
      multiline: true,
      margin: 0,
      padding: 0,
      paddingLeft: 1,
      paddingRight: 1,
    },
  };
};
