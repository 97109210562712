import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";

import Logos from "@library/components/Logos";

const BrandsWeLove = () => {
  const theme = useTheme();
  return (
    <Stack px={2} mt={1}>
      <Typography
        variant="body1"
        py={2}
        fontSize="0.9rem"
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        Brands we love
      </Typography>

      <Stack
        direction={{ xs: "column", md: "row" }}
        my={1}
        spacing={2}
        justifyContent="space-evenly"
      >
        <Stack width="33%">
          <Logos brand="hisense" />
        </Stack>
        <Stack width="33%">
          <Logos brand="amana" />
        </Stack>
        <Stack width="33%">
        <Logos brand="mitsubishi" />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BrandsWeLove;
