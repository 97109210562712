import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";
import { OnboardingPayload } from "@library/domain/onboarding";
import onboardingSchema from "../../schema";

export const schema = (
  _payload: Partial<OnboardingPayload>,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
slideState: any
) => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["houseHoldIncome"],
    properties: {
      houseHoldIncome: {
        ...props.houseHoldIncome,
        enum: slideState.HouseHoldIncomeStep?.incomeOptions,
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    width: ["100%", "75%", "50%"],
    houseHoldIncome: {
      "ui:widget": "RadioImages",
      "ui:autocomplete": "off",
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  };
};
