import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

import { baseConfig, baseOverrides } from "../config";

import bg from "./home-background.jpg";
import logo from "./logo.png";

const FONT_LIGHT = 300;
const FONT_REGULAR = 400;
const FONT_NORMAL = 500;
const FONT_MEDIUM = 600;
const FONT_BOLD = 600;

const config = merge({}, cloneDeep(baseConfig), {
  name: "A²Zero",
  language: {
    "en-us": {
      onboardingTitle:
        "Upgrade to efficient, climate-friendly, wallet-friendly home heating and cooling.",
      tier: {
        replace: "Replace",
        pearl: "Pearl",
        edison: "Edison",
      },
    },
  },
  tiers: {
    default: "pearl",
    available: ["replace", "pearl", "edison"],
  },
  rebates: {
    ami: {
      lower: "80",
      upper: "120",
    },
  },
});

const overrides = merge({}, cloneDeep(baseOverrides), {
  logo: {
    imageSrc: logo,
    alt: "A²Zero Logo",
    width: 150,
    height: 30,
    poweredBy: true,
  },
  splash: {
    imageSrc: bg,
  },
  //shadows:[],
  typography: {
    fontFamily: "Fira Sans",
    fontWeightLight: FONT_LIGHT,
    fontWeightRegular: FONT_REGULAR,
    fontWeightNormal: FONT_NORMAL,
    fontWeightMedium: FONT_MEDIUM,
    fontWeightBold: FONT_BOLD,
    h1: {
      fontWeight: FONT_REGULAR,
      fontSize: "42px",
    },
    h2: {
      fontWeight: FONT_REGULAR,
      fontSize: "25px",
    },
    h3: {
      fontWeight: FONT_REGULAR,
      fontSize: "22px",
    },
    h4: {
      fontWeight: FONT_MEDIUM,
      fontSize: "20px",
    },
    h5: {
      fontWeight: FONT_MEDIUM,
      fontSize: "18px",
    },
    body1: {
      fontWeight: 400,
      "@media (max-width:600px)": {
        fontSize: "0.8rem",
      },
    },
    body2: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: "#0b9a6d",
      light: "#3BAE8A",
    },
    secondary: {
      main: "#43749E",
      light: "#017bbc",
    },
    accent: {
      main: "#FCF2D9",
    },
    success: {
      main: "#FCF2D9",
    },
    warning: {
      main: "#97ff00", //"#7FCD0D"
    },
  },
});

export default {
  ...overrides,
  config,
};
