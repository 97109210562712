import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "../../schema";
import { Callout } from "./Components";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["whyAreYouShoppingToday"],
    properties: {
      whyAreYouShoppingToday: {
        ...props.whyAreYouShoppingToday,
      },
    },
    dependencies: {
      whyAreYouShoppingToday: {
        oneOf: [
          {
            properties: {
              whyAreYouShoppingToday: {
                ...props.whyAreYouShoppingToday,
                enum: ["equipmentBroken"],
              },
              whyAreYouShoppingTodayCallout: {
                type: "string",
              },
            },
          },
        ],
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    alignItems: "flex-start",
    maxWidth: "900px",
    mt: 3,
    whyAreYouShoppingToday: {
      "ui:widget": "RadioImages",
      "ui:autocomplete": "off",
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
      children: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
    },
    whyAreYouShoppingTodayCallout: {
      "ui:widget": Callout,
      "ui:autocomplete": "off",
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  };
};
