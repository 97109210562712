import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

import { baseConfig, baseOverrides } from "../config";

import bg from "../home-background.jpg";
import logo from "./logo.png";

// const FONT_LIGHT = 300;
const FONT_REGULAR = 400;
// const FONT_NORMAL = 500;
// const FONT_MEDIUM = 600;
// const FONT_BOLD = 600;

const config = merge({}, cloneDeep(baseConfig), {
  name: "Pearl Edison",
  language: {
    "en-us": {
      onboardingTitle:
        "Upgrade to efficient, climate-friendly, wallet-friendly home heating and cooling.",
    },
  },
  tiers: {
    default: "base",
    available: ["replace", "base", "pearl"],
  },
});

const overrides = merge({}, cloneDeep(baseOverrides), {
  logo: {
    imageSrc: logo,
    logoUrl: "https://www.pearledison.com/",
    alt: "Pearl Logo",
    width: 149,
    height: 40,
    poweredBy: false,
  },
  splash: {
    imageSrc: bg,
    alt: "Upgrade to efficient, climate-friendly, wallet-friendly home heating and cooling.",
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: "Poppins",
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightNormal: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "45px",
      // lineHeight: "60px",
    },
    h2: {
      fontWeight: FONT_REGULAR,
      fontSize: "25px",
    },
    p: {
      fontWeight: 500,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
  },
  palette: {
    action: {
      active: "#BBBBBB",
    },
    primary: {
      main: "#591E91",
      light: "#7a4ba7",
    },
    secondary: {
      main: "#2176FF",
    },
    success: {
      main: "#7CB518",
    },
    warning: {
      main: "#F2AF29",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
  },
});

export default {
  ...overrides,
  config,
};
