import * as React from "react";
import { useContext } from "react";
import Header from "./components/Header";
import { Box } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { getTheme } from "@library/theme/createDefaultTheme";
import { ChannelContext } from "@library/theme/provider";
import { SettingsProvider } from "@library/settings/provider";
import INTERCOM from "@library/scripts/intercom";
import ANALYTICS from "@library/scripts/analytics";
import { init } from "@library/scripts/sentry";

import "./App.css";

import Router from "./Router";

const helmetContext = {};

init();

function App() {
  const { channelTheme } = useContext(ChannelContext);

  return (
    <React.StrictMode>
      <HelmetProvider context={helmetContext}>
        <Helmet>
          {import.meta.env.VITE_ENV === "prod" ||
          import.meta.env.VITE_INTERCOM === "true" ? (
            <script id="intercom-load">{INTERCOM}</script>
          ) : null}
          {import.meta.env.VITE_ENV === "prod" ? (
            <script id="analyics-load">{ANALYTICS}</script>
          ) : null}
        </Helmet>
        <SettingsProvider>
          <ThemeProvider theme={getTheme(channelTheme)}>
            <CssBaseline /> {/* This normalizes styles across browsers */}
            <BrowserRouter>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100vh",
                  bgcolor: "rgb(239, 239, 239)",
                }}
              >
                <Header />
                <Router />
              </Box>
            </BrowserRouter>
          </ThemeProvider>
        </SettingsProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
}

export default App;
