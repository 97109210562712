import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "../../schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    properties: {
      communicationPreference: {
        ...props.communicationPreference,
      },
    },
    dependencies: {
      communicationPreference: {
        oneOf: [
          {
            required: ["phoneNumber"],
            properties: {
              communicationPreference: {
                ...props.communicationPreference,
                enum: ["text", "call"],
              },
              phoneNumber: {
                ...props.phoneNumber,
              },
            },
          },
        ],
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    width: ["100%", "60%"],
    communicationPreference: {
      "ui:widget": "RadioImages",
      "ui:autocomplete": "off",
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      children: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      width: "100%",
    },
    phoneNumber: {
      "ui:autocomplete": "off",
      "ui:placeholder": "(XXX) XXX-XXXX",
      format: (value: string, previousValue: string) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, "");
        const cvLength = currentValue.length;
        if (!previousValue || value.length > previousValue.length) {
          if (cvLength < 4) return currentValue;
          if (cvLength < 7)
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
          return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
      },
      width: "100%",
      margin: 0,
      paddingLeft: 2,
      paddingRight: 2,
    },
  };
};
