import React, { useState } from "react";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { getLanguage } from "@library/theme/multitenancy";
import Rebates from "@library/components/icons/Rebates.png";
import { SlideComponent } from "../../index";
import api from "@library/api";
import SkipModal from "../../SkipModal";

export const Title = ({ stepName }: SlideComponent) => {
  const theme = useTheme();
  if (!theme.config.language[getLanguage(theme)].step![stepName]!.title)
    return null;
  return (
    <Stack justifyContent="center" alignItems="center" mt={8}>
      <img width="110px" src={Rebates} alt="Rebates" />
      <Typography
        variant="h1"
        sx={{
          width: "auto",
          display: "inline-block",
          fontSize: "3.5rem !important",
          fontWeight: "bold",
          color: theme.palette.primary.contrastText,
          textAlign: "center",
        }}
      >
        {theme.config.language[getLanguage(theme)].step![stepName]!.title}
      </Typography>
    </Stack>
  );
};

export const Description = ({ stepName }: SlideComponent) => {
  const theme = useTheme();
  if (!theme.config.language[getLanguage(theme)].step![stepName]!.description)
    return null;
  return (
    <Typography
      variant="h4"
      sx={{
        width: "auto",
        display: "inline-block",
        fontWeight: "400",
        marginTop: 4,
        fontSize: "1.0rem !important",
        color: theme.palette.primary.contrastText,
      }}
    >
      {theme.config.language[getLanguage(theme)].step![stepName]!.description}
    </Typography>
  );
};

export const Header = () => {
  const theme = useTheme();
  if (theme.config?.Landing) {
    const Landing = theme.config.Landing;
    return <Landing />;
  }
};

export const Footer = ({
  setSlideDirection = () => {},
  payload,
  data: { onboarding },
  slideIndex,
  setSlideIndex = () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSpecificSlide = async (_payload: {
    index: number;
    keyIndex?: string;
    numIndex?: number;
  }) => {
    return Promise.resolve(0);
  },
}: SlideComponent) => {
  const theme = useTheme();
  const [skipModalOpen, setSkipModalOpen] = useState(false);
  const learnMoreLink =
    theme.config.language["en-us"].step?.AlmostDone?.learnMoreLink ?? "";
  const learnMoreText =
    theme.config.language["en-us"].step?.AlmostDone?.learnMoreText ?? "";

  const handleSkip = async (toSkip: boolean) => {
    setSkipModalOpen(false);
    if (toSkip) {
      const newSlideIndex = await getSpecificSlide({
        index: slideIndex,
        // keyIndex: "FinalStep"
        numIndex: slideIndex + 4,
      });
      if (newSlideIndex === slideIndex) {
        setSlideDirection(1);
      } else {
        setSlideIndex(newSlideIndex);
      }
    }
  };
  return (
    <Stack sx={{ marginTop: 2 }}>
      <Stack>
        <SkipModal
          open={skipModalOpen}
          title={theme.config.language["en-us"].step?.AlmostDone?.modalTitle}
          description={
            theme.config.language["en-us"].step?.AlmostDone?.modalDescription
          }
          onClose={(toSkip: boolean) => handleSkip(toSkip)}
        />
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setSlideDirection(-1);
            }}
            sx={{
              color: theme.palette.primary.contrastText,
              borderColor: "transparent",
              backgroundColor: "rgba(255,255,255,.2)",
              width: "150px",
            }}
          >
            Back
          </Button>
          {learnMoreLink && (
            <Button
              href={learnMoreLink}
              variant="outlined"
              color="secondary"
              target="_blank"
              sx={{
                color: theme.palette.primary.contrastText,
                borderColor: theme.palette.primary.contrastText,
                textTransform: "none",
                whiteSpace: "nowrap",
                width: "150px",
              }}
            >
              {learnMoreText}
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (onboarding?.id) {
                api
                  .put(`onboarding/${onboarding.id}`, {
                    payload,
                    activeStep: slideIndex,
                  })
                  .then(() => {
                    setSlideDirection(1);
                  });
              }
            }}
            sx={{ width: "150px" }}
          >
            Find Rebates
          </Button>
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: 2 }}
        >
          <Button
            variant="text"
            onClick={() => setSkipModalOpen(true)}
            color="secondary"
            sx={{
              textTransform: "none",
              fontWeight: 400,
              color: theme.palette.primary.contrastText,
            }}
          >
            Skip this step
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
