import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";


export const schema = () => {
  return {
    type: "object",
    properties: {
      user: {
        required: ["address"],
        type: "object",
        properties: {
          address: {
            title: "Address",
            type: "string",
          },
        },
      },
    },
  };
}

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 3,
    width: "100%",
    maxWidth: "900px",
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    user: {
      "ui:options": {
        label: false,
      },
      address: {
        "ui:widget": "AutocompleteAddress",
        "ui:autocomplete": "off",
        width: "100%",
      },
    },
  };
}