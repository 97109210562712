import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "../../schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["windowType"],
    properties: {
      windowType: {
        ...props.windowType,
      },
    },
  };
}

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    maxWidth: "700px",
    spacing: 2,
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    windowType: {
      "ui:widget": "RadioImages",
      "ui:autocomplete": "off",
      inverted: true,
      borderRadius: 0,
    },
  };
}