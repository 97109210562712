import React, {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import find from "lodash/find";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "@library/custom.d.ts";
import { Estimate, EstimateData } from "@library/domain/estimate";

import { getLanguage } from "@library/theme/multitenancy";

import { SettingsContext } from "@library/settings/provider";
import { Quote } from "@library/domain/quote";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import api from "@library/api";

import EstimateOption from "./EstimateOption";
import EstimateDetail from "./EstimateDetail";
import WaitingForEstimates from "./WaitingForEstimates";
import CalendlyModal from "./CalendlyModal";
import { User } from "@library/domain/user";

interface Option {
  id: string;
  tier: string;
  recommended: boolean;
  visible: boolean;
  topTitle: string;
  subTitle: string;
  selected: boolean;
  value: EstimateData;
  onClick: (tier: string, id: string) => void;
  filter: string | undefined;
}

const Tier = ({
  id,
  tier,
  onClick,
  filter,
  visible,
  value,
  recommended,
  selected,
  topTitle,
  subTitle,
}: Option) => {
  return (
    <Button
      component="div"
      onClick={() => {
        onClick(tier, id);
        const element = document.getElementById("estimate-detail");
        if (element) {
          const rect = element.getBoundingClientRect();
          const y = rect.top + window.scrollY;
          window.scrollTo(0, y - 120);
        }
      }}
    >
      <EstimateOption
        topTitle={topTitle}
        subTitle={subTitle}
        value={value}
        recommended={recommended || false}
        selected={selected || false}
        visible={visible ?? false}
        filter={filter}
      />
    </Button>
  );
};

export const QuoteContext = createContext<{
  quoteId: string;
  quote: Partial<Quote>;
}>({
  quoteId: "",
  quote: {},
});

type Timer = ReturnType<typeof setTimeout>;

export const ConciergeView = ({
  zoom,
  filter,
  borderRadius,
  user,
  matches,
  quote,
  selectedTier,
  setSelectedEstimate,
  quoteId,
  setError,
  setWarning,
  setShowCalendly,
}: {
  zoom: string | undefined;
  filter: string | undefined;
  borderRadius: string;
  user: Partial<User>;
  matches: boolean;
  quote: Quote;
  selectedTier: string;
  setSelectedEstimate: (val: string) => void;
  quoteId: string;
  setError: (val: string) => void;
  setWarning: (val: string) => void;
  setShowCalendly: (val: boolean) => void;
}) => {
  const theme = useTheme();

  const estimates = (quote?.Estimate || []) as Estimate[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const estimateMap: Record<string, any> = {};
  estimates.forEach((estimate: Estimate) => {
    let selected = false;
    if (selectedTier === estimate.tier) selected = true;
    if (
      !estimateMap[estimate.tier] ||
      estimateMap[estimate.tier].createdAt < estimate.createdAt
    ) {
      estimateMap[estimate.tier ?? "null"] = {
        ...estimate,
        selected,
      };
    }
  });

  const options = {
    free: {
      topTitle: theme.config.language[getLanguage(theme)].tier?.free ?? "Free",
      subTitle: "If you're rebate-eligible, you may have a free option",
      recommended: false,
      id: estimateMap.free?.id,
      value: estimateMap.free?.data ?? {},
      visible: theme.config.tiers?.available
        ? theme.config.tiers.available.includes("free")
        : false,
    },
    replace: {
      topTitle:
        theme.config.language[getLanguage(theme)].tier?.replace ?? "Replace",
      subTitle: "A like-for-like replacement of your current system",
      recommended: false,
      id: estimateMap.replace?.id,
      value: estimateMap.replace?.data ?? {},
      visible: theme.config.tiers?.available
        ? theme.config.tiers.available.includes("replace")
        : false,
    },
    base: {
      topTitle: theme.config.language[getLanguage(theme)].tier?.base ?? "Base",
      subTitle: "We recommend this option to minimize upfront cost",
      recommended: true,
      id: estimateMap.base?.id,
      value: estimateMap.base?.data ?? {},
      visible: theme.config.tiers?.available
        ? theme.config.tiers.available.includes("base")
        : false,
    },
    pearl: {
      topTitle:
        theme.config.language[getLanguage(theme)].tier?.pearl ?? "Pearl",
      subTitle: "We recommend this option to optimize your energy savings",
      recommended: false,
      id: estimateMap.pearl?.id,
      value: estimateMap.pearl?.data ?? {},
      visible: theme.config.tiers?.available
        ? theme.config.tiers.available.includes("pearl")
        : false,
    },
    edison: {
      topTitle:
        theme.config.language[getLanguage(theme)].tier?.edison ?? "Edison",
      subTitle: "We recommend this option to minimize climate impact",
      recommended: false,
      id: estimateMap.edison?.id,
      value: estimateMap.edison?.data ?? {},
      visible: theme.config.tiers?.available
        ? theme.config.tiers.available.includes("edison")
        : false,
    },
  };

  const estimate = options[selectedTier as keyof typeof options];

  return (
    <Box
      sx={{
        width: "100%",
        zoom,
        borderRadius,
        p: [0, 2],
        backgroundColor: theme.palette.primary.contrastText,
      }}
    >
      <Typography
        ml={2}
        flexGrow={1}
        pr={[2]}
        pt={2}
        sx={{
          fontSize: ["1.5rem", "2.5rem"],
          fontWeight: theme.typography.fontWeightBold,
        }}
      >
        Here&rsquo;s your estimate,{" "}
        <span style={{ filter }}>{user?.firstName}</span>!
      </Typography>
      <Typography
        ml={2}
        flexGrow={1}
        variant="body1"
        textAlign="left"
        display="block"
        fontSize="1.6rem"
        pr={[2]}
      >
        Compare your options, then connect with a Pearl Advisor to finalize your
        quote.
      </Typography>
      <ButtonGroup
        variant="text"
        orientation={matches ? "horizontal" : "vertical"}
        sx={{
          ".MuiButtonGroup-grouped": {
            borderColor: "transparent",
            textTransform: "initial",
            color: "initial",
            fontWeight: "initial",
          },
          mt: [4],
          mb: [10],
        }}
        aria-label="Estimate tiers"
      >
        {(Object.keys(options) as (keyof typeof options)[]).map((key) => {
          const tier = options[key];
          if (!tier.visible) return;
          return (
            <Tier
              key={key}
              filter={filter}
              tier={key}
              selected={selectedTier === key}
              onClick={setSelectedEstimate}
              {...tier}
            />
          );
        })}
      </ButtonGroup>
      <Box m={2} id="estimate-detail">
        <EstimateDetail
          quoteId={quoteId}
          estimateId={estimate.id}
          tier={selectedTier}
          estimate={estimate.value}
          setError={setError}
          setWarning={setWarning}
          setShowCalendly={setShowCalendly}
        />
      </Box>
    </Box>
  );
};

const ConciergePageView = ({
  zoom = "1.0",
  borderRadius = "0px",
  filter = undefined,
}: {
  zoom?: string;
  borderRadius?: string;
  filter?: string | undefined;
  backgroundColor?: string | undefined;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [intervalId, setIntervalId] = useState<Timer | null>(null);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [error, setError] = useState<string | ReactElement>("");
  const [warning, setWarning] = useState("");
  const [showCalendly, setShowCalendly] = useState(false);
  const [waitingForEstimates, setWaitingForEstimates] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(SettingsContext);
  const [selectedTier, setSelectedTierValue] = useState(
    theme.config.tiers.default,
  );
  const { quoteId } = useParams();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const quote = useMemo(() => {
    return find(quotes, { id: quoteId }) || ({} as Quote);
  }, [quoteId, quotes]);

  const setSelectedEstimate = useCallback(
    (tier: string, estimateId?: string) => {
      setSelectedTierValue(tier);
      if (estimateId) {
        api
          .put(
            "quote",
            { userInput: { selectedTier: tier, estimateId } },
            { id: quote.id },
          )
          .catch(() => {
            setWarning(
              "A backend error occured while carrying out your last request.",
            );
            setIsLoading(false);
          });
      }
    },
    [quote],
  );

  useEffect(() => {
    if (!quote?.id) return;
    if (quote?.userInput?.selectedTier) {
      setSelectedEstimate(quote.userInput.selectedTier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote]);

  useEffect(() => {
    if (!user.email) return;
    if (quoteId) return;
    if (hasFetched) return;
    clearInterval(intervalId as Timer);
    setHasFetched(true);
    setIsLoading(true);
    api
      .get("quote", { models: "Estimate" })
      .then((response) => {
        const { quotes = [] } = response;
        setQuotes(quotes);
        if (quotes.length) {
          const quote = find(quotes, (q) => q.status === "ESTIMATE_CREATED");
          if (quote) {
            const _quoteId = quote.id;
            setIsLoading(false);
            setWaitingForEstimates(false);
            navigate(`/concierge/${_quoteId}`);
          } else {
            const waitingForEstimatesQuote = find(
              quotes,
              (q) => q.status === "WAITING_FOR_ESTIMATES",
            );
            if (waitingForEstimatesQuote) {
              setIsLoading(false);
              setWaitingForEstimates(true);
              const _intervalId = setTimeout(() => {
                setHasFetched(false);
              }, 5000);
              setIntervalId(_intervalId);
            } else {
              navigate("/onboarding");
            }
          }
        } else {
          // setError("No quotes found for this user. Please contact support.");
          navigate("/onboarding");
        }
        setIsLoading(false);
      })
      .catch(() => {
        setError("An unknown error occured, please try again.");
        setIsLoading(false);
      });
    return () => {
      if (intervalId) clearTimeout(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetched, quoteId, intervalId, user]);

  useEffect(() => {
    if (!quoteId) return;
    if (hasFetched) return;
    clearInterval(intervalId as Timer);
    setHasFetched(true);
    setIsLoading(true);
    api
      .get("quote", { id: quoteId, models: "Estimate" })
      .then((response) => {
        const { quote = {} } = response;
        setQuotes([quote]);
        if (quote?.id) {
          if (quote?.Estimate && quote.Estimate.length) {
            setIsLoading(false);
            setWaitingForEstimates(false);
          } else {
            setIsLoading(false);
            setWaitingForEstimates(true);
            const _intervalId = setTimeout(() => {
              setHasFetched(false);
            }, 5000);
            setIntervalId(_intervalId);
          }
        } else {
          // setError("No quotes found for this user. Please contact support.");
          navigate("/onboarding");
        }
        setIsLoading(false);
      })
      .catch(() => {
        setError("An unknown error occured, please try again.");
        setIsLoading(false);
      });
    return () => {
      if (intervalId) clearTimeout(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetched, quoteId, intervalId, user]);

  return (
    <>
      {warning ? (
        <Box m={2}>
          <Alert severity="warning">{warning}</Alert>
        </Box>
      ) : null}
      {error ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "calc(100vh - 10vh)" }}
        >
          <Grid item xs={3}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        </Grid>
      ) : !user.email && !quoteId ? (
        <Navigate to="/login" />
      ) : waitingForEstimates ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "calc(100vh - 10vh)" }}
        >
          <Grid item xs={3}>
            <WaitingForEstimates />
          </Grid>
        </Grid>
      ) : isLoading || !quoteId ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "calc(100vh - 10vh)" }}
        >
          <Grid item xs={3}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <QuoteContext.Provider value={{ quoteId, quote }}>
          {showCalendly ? (
            <CalendlyModal
              handleClose={setShowCalendly}
              setWarning={setWarning}
            />
          ) : null}
          <ConciergeView
            zoom={zoom}
            filter={filter}
            borderRadius={borderRadius}
            user={user}
            matches={matches}
            selectedTier={selectedTier}
            quote={quote}
            setSelectedEstimate={setSelectedEstimate}
            quoteId={quoteId}
            setError={setError}
            setWarning={setWarning}
            setShowCalendly={setShowCalendly}
          />
        </QuoteContext.Provider>
      )}
    </>
  );
};

export default ConciergePageView;
