import { useCallback, useState } from "react";

export const useSwitch = (defaultValue = false) => {
  const [on, setOn] = useState(defaultValue);
  const toggle = useCallback(() => setOn((prev) => !prev), []);
  const turnOn = useCallback(() => setOn(true), []);
  const turnOff = useCallback(() => setOn(false), []);
  return { on, off: !on, toggle, turnOn, turnOff };
};

export default useSwitch;