import React, { createContext, useEffect, useState } from "react";

import { User } from "@library/domain/user";
import api from "@library/api";
import supabase from "@library/api/supabase";
import { useIntercom } from "@library/hooks/intercom";

interface SettingsContextValue {
  error: string;
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  user: User;
  setUser: (value: User) => void;
  signOut: () => void;
}

export const SettingsContext = createContext<SettingsContextValue>({
  error: "",
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  isLoading: true,
  setIsLoading: () => {},
  user: {
    role: "anonymous",
  },
  setUser: () => {},
  signOut: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const SettingsProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = useState(
    localStorage.getItem(
      `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`,
    ),
  );
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [user, setUser] = useState<User>({
    role: "anonymous",
    firstName: "Guest",
    lastName: "User",
  });

  useIntercom(user, isLoading);

  useEffect(() => {
    setIsLoading(true);
    api
      .get("auth/settings")
      .then((response) => {
        const { data = {} } = response;
        const { id } = data;
        if (id && id.length > 1) {
          setIsAuthenticated(true);
        }
        setUser({
          ...data,
        });
        setIsLoading(false);
      })
      .catch(() => {
        setError("An unknown error occured, please try again.");
        setIsLoading(false);
      });
  }, [token, isAuthenticated]);

  useEffect(() => {
    supabase.auth.onAuthStateChange((event) => {
      if (window.location.href.includes("/login")) {
        if (isAuthenticated) return;
        if (event === "SIGNED_IN") {
          setIsLoading(true);
          setIsAuthenticated(true);
        }
      } else {
        if (isAuthenticated) return;
        if (event === "TOKEN_REFRESHED") {
          setIsLoading(true);
          setToken(
            localStorage.getItem(
              `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`,
            ),
          );
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOut = async () => {
    document.cookie = `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token-code-verifier=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    const { error } = await supabase.auth.signOut();
    if (error) {
      setError(error?.message);
    } else {
      setError("");
      window.location.href = `${window.location.origin}`;
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        error,
        isLoading,
        isAuthenticated,
        setIsLoading,
        setIsAuthenticated,
        user,
        setUser,
        signOut,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
