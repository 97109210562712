import React from 'react';

export const Clock = ({color}: { color: string}) => {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50.9614 76.0273C69.681 71.5453 81.2229 52.7367 76.7409 34.0171C72.2589 15.2975 53.4504 3.7557 34.7308 8.23768C16.0112 12.7197 4.46929 31.5282 8.95127 50.2478C13.4333 68.9674 32.2418 80.5092 50.9614 76.0273Z" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M42.8477 25.8733V42.7036H59.686" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M42.8477 13.8882V18.9938" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28.7227 17.6719L31.2754 22.0933" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.3867 28.0095L22.8083 30.5623" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.6016 42.1323H19.7071" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.3867 56.2537L22.8083 53.7009" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28.7227 66.5922L31.2754 62.1707" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M42.8477 70.3767V65.2712" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M56.9669 66.5922L54.4141 62.1707" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M67.3083 56.2537L62.8867 53.7009" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M71.09 42.1323H65.9844" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M67.3083 28.0095L62.8867 30.5623" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M56.9669 17.6719L54.4141 22.0933" stroke={color} stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Clock;
