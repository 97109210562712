import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { WidgetProps } from "@rjsf/utils";

interface StreetViewProps {
  apiKey?: string;
  lat: number;
  lng: number;
}

const StreetView: React.FC<StreetViewProps> = ({
  apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  lat,
  lng,
}) => {
  return (
    <img
      src={`https://maps.googleapis.com/maps/api/streetview?size=400x250&location=${lat},${lng}&key=${apiKey}`}
      width="400px"
      height="250px"
      alt="Your house"
      title="Your house"
    />
  );
};

export const StaticComponent = (props: WidgetProps) => {
  const theme = useTheme();
  const { address, lat, lng } = props.formData ?? {};
  return (
    <>
      {lat && lng && (
        <Stack justifyContent="center" alignItems="center" mt={3}>
          <Stack
            sx={{
              maxWidth: "600px",
              backgroundColor: theme.palette.primary.contrastText,
              p: 2,
              borderRadius: 2,
            }}
            alignItems="center"
            justifyContent="center"
          >
            <StreetView lat={lat} lng={lng} />
            <Typography mt={2}>{address}</Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default StaticComponent;
