import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

import { baseConfig, baseOverrides } from "../config";

import Landing from "./Landing";
import logo from "./logo.png";
import React from "react";

const FONT_LIGHT = 300;
const FONT_REGULAR = 400;
const FONT_NORMAL = 500;
const FONT_MEDIUM = 600;
const FONT_BOLD = 600;

const config = merge({}, cloneDeep(baseConfig), {
  name: "DTE Energy",
  Landing,
  showBrands: false,
  language: {
    "en-us": {
      onboardingTitle:
        "Upgrade to efficient, climate-friendly, wallet-friendly home heating and cooling.",
      enterAddressPlaceholder: "Enter your address",
      addessSearchButtonText: "Get a free quote",
      tier: {
        base: "Budget Solution",
        pearl: "Savings Solution",
        edison: "Clean Solution",
      },
      step: {
        AddressStep: {
          title: "",
          description: "",
        },
        OnboardingStep: {
          title: "",
          description: "",
        },
        GettingStartedStep: { title: "", description: "" },
        HomeConfirmationStep: { title: "Is this your home?", description: "" },
        FullNameStep: {
          title: "To personalize your quote, let's begin with your name.",
          description:
            "We'll send you a link to your estimate when it's complete.",
        },
        HomeProfileStep: { title: "Please confirm details about your home." },
        FloorSizeStep: {
          title:
            "Tell us about your home's floor space.\nHow large is each floor?",
          description: "",
        },
        BasementTypeStep: {
          title: "Which image best describes your basement?",
          description: "",
        },
        BasementFinishedStep: {
          title: "Is your basement finished?",
          description: "",
        },
        AtticTypeStep: {
          title: "Which attic is most like yours?",
          description: "",
        },
        AtticFinishedStep: {
          title: "Is your attic finished?",
          description: "",
        },
        GarageStep: {
          title: "Which image best describes your garage?",
          description: "",
        },
        FloorPlanStep: {
          title: "List all of the rooms in your home.",
          description: "",
        },
        RoomsConfigurationStep: {
          title: "Let's finalize your floor plan.",
          description: "",
        },
        HeatingEquipmentStep: {
          title: "What is your current heating system?",
          description: "",
        },
        FuelSourceStep: { title: "How is your home heated?", description: "" },
        HeatRoomsStep: {
          title:
            "Which rooms are heated?\nAre there comfort issues during winter?",
          description: "",
        },
        AirConditioningStep: {
          title: "What is your current air conditioning system?",
          description: "",
        },
        AirConditioningRoomsStep: {
          title:
            "Which rooms have air conditioning?\nAre there comfort issues during summer?",
          description: "",
        },
        MaterialsSteps: {
          title:
            "Please identify the primary material in your home's exterior and interior walls.",
          description: "",
        },
        WindowsStep: {
          title: "Which type of window is most common in your home?",
          description: "",
        },
        UpgradeStep: {
          title: "Has your home had any of these upgrades?",
          description: "",
        },
        IssuesStep: {
          title: "Are any of these issues affecting your home?",
          description: "",
        },
        TemperatureStep: {
          title: "What are your usual thermostat settings?",
          description: "",
        },
        DraftyStuffyStep: {
          title: "Does your home ever feel “drafty” or “stuffy”?",
          description: "",
        },
        HomeQualityStep: {
          title: "Does your home maintain a comfortable temperature?",
          description: "",
        },
        WhyAreYouShoppingStep: {
          title:
            "Why are you interested in upgrading your heating and air conditioning?",
          description: "",
        },
        WhatsImportantToYouStep: {
          title: "What's important to you?",
          description: "",
        },
        AlmostDone: {
          title: "Let's see if you qualify for rebates.",
          description: [
            "Please note: Some rebates depend on annual income and family size. To get you the best possible deal, we need to ask about your family, income, and finances. We value your privacy and do not share this information. You may want to review the State of Michigan's ",
            <a
              href="https://www.michigan.gov/egle/about/organization/materials-management/energy/rfps-loans/home-energy-rebate-programs"
              target="_blank"
              style={{ color: "#FFFFFF" }}
            >
              Home Energy Rebate Programs
            </a>,
            " or you can skip this step.",
          ],
          modalTitle: "Rebates and incentives will lower your price.",
          modalDescription:
            "Most customers reduce the cost of their new system by over $2,000.",
          // learnMoreText: "Learn More",
          // learnMoreLink:
          //   "https://www.michigan.gov/egle/about/organization/materials-management/energy/rfps-loans/home-energy-rebate-programs",
        },
        OwnOrRentStep: {
          title: "Do you own or rent your home?",
          description:
            "This information helps determine if you are eligible for rebates and incentives, which we will apply to ensure that you get the best price.",
        },
        PeopleLiveInHomeStep: {
          title: "How many people live in your home?",
          description:
            "This information helps determine if you are eligible for rebates and incentives, which we will apply to ensure that you get the best price.",
        },
        HouseHoldIncomeStep: {
          title: "What is your annual household income?",
          description:
            "This information helps determine if you are eligible for rebates and incentives, which we will apply to ensure that you get the best price.",
        },
        CommunicationPreferencesStep: { title: "", description: "" },
        GetEstimates: {
          title: "Thank you. We're creating your quote!",
          description: "",
        },
      },
    },
  },
  tiers: {
    default: "pearl",
    available: ["base", "pearl"],
  },
});

const overrides = merge({}, cloneDeep(baseOverrides), {
  logo: {
    imageSrc: logo,
    logoUrl: `${window.location.origin}/onboarding?s=0`,
    alt: "DTE Logo",
    width: 250,
    poweredBy: false,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          padding: 10,
        },
      },
    },
  },
  //shadows:[],
  typography: {
    fontFamily: "Open Sans",
    fontWeightLight: FONT_LIGHT,
    fontWeightRegular: FONT_REGULAR,
    fontWeightNormal: FONT_NORMAL,
    fontWeightMedium: FONT_MEDIUM,
    fontWeightBold: FONT_BOLD,
    h1: {
      fontWeight: FONT_REGULAR,
      fontSize: "28px",
      fontFamily: "Open Sans",
    },
    h2: {
      fontWeight: FONT_REGULAR,
      fontSize: "25px",
      fontFamily: "Open Sans",
    },
    h3: {
      fontWeight: FONT_REGULAR,
      fontSize: "22px",
      fontFamily: "Open Sans",
    },
    h4: {
      fontWeight: FONT_MEDIUM,
      fontSize: "20px",
      fontFamily: "Open Sans",
    },
    h5: {
      fontWeight: FONT_MEDIUM,
      fontSize: "18px",
      fontFamily: "Open Sans",
    },
    body1: {
      fontWeight: 400,
      "@media (max-width:600px)": {
        fontSize: "0.8rem",
      },
      fontFamily: "Open Sans",
    },
    body2: {
      fontWeight: 400,
      fontFamily: "Open Sans",
    },
  },
  palette: {
    primary: {
      main: "#1e3575",
      light: "#334881",
    },
    secondary: {
      main: "#0072CE",
      light: "#59BEC9",
    },
    accent: {
      main: "#D2D755",
    },
    success: {
      main: "#0072CE",
      light: "#7CB518",
    },
    warning: {
      main: "#8DC8E8",
    },
  },
});

export default {
  ...overrides,
  config,
};
