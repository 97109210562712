import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

export default [
  {
    label: "Visit DTEEnergy.com",
    href: "https://www.dteenergy.com/",
    target: "_blank",
    Icon: ArrowOutwardIcon,
  },
];
