import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";
import onboardingSchema from "../../schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["draftyStuffy"],
    properties: {
      draftyStuffy: {
        ...props.draftyStuffy,
      },
    },
    dependencies: {
      draftyStuffy: {
        oneOf: [
          {
            properties: {
              draftyStuffy: {
                ...props.draftyStuffy,
                enum: ["yes_drafty", "yes_stuffy"],
              },
              draftyStuffyMoreInfo: {
                ...props.draftyStuffyMoreInfo,
              },
            },
          },
        ],
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 3,
    maxWidth: "500px",
    draftyStuffy: {
      "ui:widget": "RadioImages",
      "ui:autocomplete": "off",
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
    draftyStuffyMoreInfo: {
      "ui:autocomplete": "off",
      multiline: true,
      margin: 0,
      padding: 0,
      paddingLeft: 3,
      paddingRight: 3,
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  };
};
