import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "../../schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["whatsImportantToYou"],
    properties: {
      whatsImportantToYou: {
        ...props.whatsImportantToYou,
        minItems: 1,
      },
    },
    dependencies: {
      whatsImportantToYou: {
        oneOf: [
          {
            properties: {
              whatsImportantToYou: {
                ...props.whatsImportantToYou,
                contains: { const: "somethingElse" },
              },
              whatsImportantToYouInfo: {
                ...props.whatsImportantToYouInfo,
              },
            },
          },
        ],
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    maxWidth: "500px",
    mt: 4,
    whatsImportantToYou: {
      "ui:widget": "CheckboxImages",
      "ui:autocomplete": "off",
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
    whatsImportantToYouInfo: {
      "ui:autocomplete": "off",
      margin: 0,
      padding: 0,
      paddingLeft: 1,
      paddingRight: 1,
      mt: 1,
      mb: 1,
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  };
};
