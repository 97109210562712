import React from "react";
import { WidgetProps } from "@rjsf/utils";
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  Stack,
} from "@mui/material";

export const CustomSelectField = (props: WidgetProps) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const {
    padding = 0,
    paddingLeft = 0,
    paddingRight = 0,
    left = 0,
    labelPlacement = "inset",
    variant = "outlined",
  } = props.uiSchema ?? {};
  return (
    <Stack p={1}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
        sx={{
          padding,
          paddingLeft,
          paddingRight,
          left,
          position: "relative",
          backgroundColor: props.rawErrors?.length
            ? "rgba(255,0,0,0.1)"
            : undefined,
        }}
      >
        <Grid
          item
          xs={labelPlacement === "inline" ? 12 : 0}
          sm={labelPlacement === "inline" ? 12 : 0}
          md={labelPlacement === "inline" ? 8 : 0}
          sx={{
            marginBottom: small ? 1 : 0,
            marginTop: small ? 1 : 0,
          }}
        >
          {labelPlacement === "inline" ? (
            <Typography>{props.label}</Typography>
          ) : null}
        </Grid>
        <Grid
          item
          xs={labelPlacement === "inline" ? 12 : 0}
          sm={labelPlacement === "inline" ? 12 : 0}
          md={labelPlacement === "inline" ? 4 : 12}
        >
          <Select
            variant={variant}
            value={props.value}
            onChange={(event) => {
              const value = event.target.value as string | number;
              props.onChange(value);
            }}
            size="small"
            sx={{ ml: 1, width: "100%", left: "-7px", p: 1 }}
          >
            {props.options?.enumOptions?.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ({ value, label }: { value: any; label: string }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ),
            )}
          </Select>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CustomSelectField;
