// import type { StaticImageData } from "next/image";
// import type { LinkProps } from "next/link";
// import NextLink from "next/link";

import type { Theme } from "@mui/material";
import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";

import { baseConfig, baseOverrides } from "./config";

// Tenants
import a2zeroTheme from "./a2zero";
import a2zeroTopLinks from "./a2zero/topLinks";
import baileyparkTheme from "./baileypark";
import baileyparkTopLinks from "./baileypark/topLinks";
import dteTheme from "./dte";
import dteTopLinks from "./dte/topLinks";
import oakgovTheme from "./oakgov";
import oakgovTopLinks from "./oakgov/topLinks";
import pearlTheme from "./pearl";
import pearlTopLinks from "./pearl/topLinks";
import contractorTheme from "./contractor";
import contractorTopLinks from "./contractor/topLinks";

interface languageStep {
  title: string;
  description: string;
  whyAreWeAsking?: string | undefined;
  learnMoreLink?: string | undefined;
  learnMoreText?: string | undefined;
  modalTitle?: string | undefined;
  modalDescription?: string | undefined;
}

interface TennantConfig {
  rebates?: {
    ami?: {
      lower: string;
      upper: string;
    };
  };
  name: string;
  Landing?: React.FC;
  showBrands?: boolean;
  language: {
    "en-us": {
      enterAddressPlaceholder?: string;
      addessSearchButtonText?: string;
      onboardingTitle?: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      labels?: any;
      tier?: Record<string, string>;
      step?: Record<string, languageStep>;
    };
  };
  tiers: {
    default: string;
    available: string[];
  };
}

declare module "@mui/material/styles" {
  interface Theme {
    config: TennantConfig;
  }

  interface ThemeOptions {
    logo?: {
      imageSrc?: string;
      logoUrl?: string;
      alt?: string;
      width: number;
      height: number;
      poweredBy: boolean;
    };
    splash?: {
      imageSrc?: string;
      alt?: string;
    };
    config: TennantConfig;
  }
}

// TopLinks
export type TopLink =
  | {
      label: string;
      href: string;
      menu?: undefined;
      target?: string;
      Icon?: React.FC;
    }
  | {
      label: string;
      menu: TopLink[];
      href?: undefined;
      target?: string;
      Icon?: React.FC;
    };

export const baseTheme = createTheme({
  ...baseOverrides,
  config: baseConfig,
});

interface TenantConfig {
  pearl: {
    theme: Theme;
    topLinks: TopLink[];
  };
  dte: {
    theme: Theme;
    topLinks: TopLink[];
  };
  baileypark: {
    theme: Theme;
    topLinks: TopLink[];
  };
  a2zero: {
    theme: Theme;
    topLinks: TopLink[];
  };
  oakgov: {
    theme: Theme;
    topLinks: TopLink[];
  };
  contractor: {
    theme: Theme;
    topLinks: TopLink[];
  };
}

export type TenantName = keyof TenantConfig;

export const AvailableTenants: TenantConfig = {
  pearl: {
    theme: createTheme(deepmerge(baseTheme, pearlTheme)),
    topLinks: pearlTopLinks,
  },
  dte: {
    theme: createTheme(deepmerge(baseTheme, dteTheme)),
    topLinks: dteTopLinks,
  },
  baileypark: {
    theme: createTheme(deepmerge(baseTheme, baileyparkTheme)),
    topLinks: baileyparkTopLinks,
  },
  a2zero: {
    theme: createTheme(deepmerge(baseTheme, a2zeroTheme)),
    topLinks: a2zeroTopLinks,
  },
  oakgov: {
    theme: createTheme(deepmerge(baseTheme, oakgovTheme)),
    topLinks: oakgovTopLinks,
  },
  contractor: {
    theme: createTheme(deepmerge(baseTheme, contractorTheme)),
    topLinks: contractorTopLinks,
  },
};

export const isAvailableTenant = (channel: string): boolean => {
  if (Object.keys(AvailableTenants).includes(channel)) return true;
  return false;
};

const languages = {
  "en-us": true,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getLanguage = (_theme: Theme) => {
  return "en-us" as keyof typeof languages;
};
