import { useState, useRef, useEffect } from "react";
import toNumber from "lodash/toNumber";

let CORP_HOST = "";
if (import.meta.env.ENV === "prod") {
  CORP_HOST = "https://pearledison.com";
} else if (import.meta.env.ENV === "staging") {
  CORP_HOST = "https://preview.pearledison.com";
} else {
  CORP_HOST = "";
}

export const useIsTruncated = () => {
  const [isTruncated, setIsTruncated] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight);
    }
  }, []);

  return [ref, isTruncated] as const;
};

// custom hook for dynamic displaying when text is truncated
export const useIsHorizontallyTruncated = (content: React.ReactNode) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const checkTruncation = () => {
      const element = ref.current;
      if (element) {
        setIsTruncated(element.scrollWidth > element.clientWidth);
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);
    return () => window.removeEventListener("resize", checkTruncation);
  }, [content]);

  return [ref, isTruncated] as const;
};

export const corpUrl = (path: string) => `${CORP_HOST}${path}`;

export const shortenFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined,
) => {
  const lastLetter = lastName?.charAt(0).toUpperCase();

  return `${firstName} ${lastLetter}`;
};

export const formatPropertyName = (name: string = ""): string => {
  return name
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

interface FormatNumberOpts {
  round?: boolean;
  empty?: boolean;
  sign?: boolean;
  toFixed?: number;
  showNegative?: boolean;
  increaseDecrease?: boolean;
  units?: string;
}

export const formatNumber = (
  value: number | string | null | undefined,
  opts?: FormatNumberOpts,
) => {
  if (opts?.empty && !value) return "";
  if (!value) return new Intl.NumberFormat().format(0);

  let newValue = value;
  if (typeof newValue === "string") {
    newValue = newValue.replace(/,/g, "");
  }
  newValue = toNumber(newValue);

  if (opts?.round) {
    newValue = Math.round(newValue);
  }

  let formatted = new Intl.NumberFormat("en", {
    minimumFractionDigits: opts?.toFixed ?? 0,
  }).format(newValue);

  if (opts?.units) {
    formatted = `${formatted}${opts.units}`;
  }

  if (opts?.increaseDecrease) {
    if (formatted.includes("-")) {
      formatted = formatted.replace(/-/g, "");
      formatted = `${formatted} decrease`;
    } else {
      formatted = `${formatted} increase`;
    }
  }

  if (opts?.showNegative === false) {
    formatted = formatted.replace(/-/g, "");
  }

  return formatted;
};

const defaultOpts = { round: true, sign: true, empty: false };

export const formatCurrency = (
  value: number | string | null | undefined,
  opts: FormatNumberOpts = { round: true, sign: true, empty: false },
) => {
  if (opts?.empty && !value) return "";
  const fullOpts = { ...defaultOpts, ...opts };
  return `${fullOpts.sign ? "$" : ""}${formatNumber(value, fullOpts)}`;
};

export const camelCaseToHuman = (camelCaseString: string): string => {
  let result = camelCaseString.replace(/_/g, " ");
  result = result.replace(/([A-Z])/g, " $1").toLowerCase();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const chunksToGrid = (chunks: number = 3) => {
  if (!chunks) return undefined;
  if (chunks == 1) return 12;
  if (chunks == 2) return 6;
  if (chunks == 3) return 4;
  if (chunks == 4) return 3;
  if (chunks == 5) return 2.4;
  if (chunks == 6) return 2;
  if (chunks == 7) return 1.714;
  if (chunks == 8) return 1.5;
  return 4;
};

export const operationalStates = ["MI"];

export const JOB_STATUS = {
  10: "Onboarding",
  20: "Instant Estimate",
  30: "Home Assessment",
  40: "Final Quote",
  50: "Contracted",
  60: "Scheduled",
  80: "Complete",
  100: "Closed-Win",
};

export const TIERS = {
  free: "Free",
  base: "Base",
  replace: "Replace",
  pearl: "Pearl",
  edison: "Edison",
};

export const camelToHuman = (str: string) => {
  let output = "";
  const len = str.length;
  let char;

  for (let i = 0; i < len; i++) {
    char = str.charAt(i);

    if (i == 0) {
      output += char.toUpperCase();
    } else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
      output += " " + char;
    } else if (char == "-" || char == "_") {
      output += " ";
    } else {
      output += char;
    }
  }

  return output;
};
