import onboardingSchema from "../../schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["rooms"],
    properties: {
      rooms: {
        ...props.rooms,
        title: "Rooms Configuration",
      },
    },
  };
}

export const uiSchema = () => {
  return {
    rooms: {
      "ui:widget": "RoomsConfiguration",
      "ui:autocomplete": "off",
    },
  };
}