import api from "@library/api";
import { formatCurrency } from "@library/common";
import { OnboardingPayload } from "@library/domain/onboarding";
import { Theme } from "@mui/material";

interface IncomeRange {
  incomeAmount: number;
}

export const buildIncomeOptions = (data: IncomeRange[]) => {
  const incomes = data?.map((x) => x.incomeAmount) ?? [];

  if (!incomes.length) return [];

  const [lbIncome, upIncome] = incomes;
  const incomeLabels = [
    `Less than ${formatCurrency(lbIncome)}`,
    `Between ${formatCurrency(lbIncome)} - ${formatCurrency(upIncome)}`,
    `More than ${formatCurrency(upIncome)}`,
  ];

  return incomeLabels;
};

export const fetchOnLoad = async ({
  slideState = { HouseHoldIncomeStep: {} },
  payload = {},
  theme,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slideState: any;
  payload: Partial<OnboardingPayload>;
  theme: Theme;
}) => {
  // @TODO if we can't find their county by fips, we may want to show
  // an error and say they have to skip the slide? Defaulting to "something"
  // is better than nothing IMO
  const ami = theme.config?.rebates?.ami ?? {
    lower: "80",
    upper: "150",
  };
  const { data } = await api.get("onboarding/fips", {
    peopleLiveInHome: payload.peopleLiveInHome,
    fips: payload.user?.fipsCountyCode ?? "26163",
    lower: ami.lower,
    upper: ami.upper,
  });
  const incomeOptions = buildIncomeOptions(data);
  const HouseHoldIncomeStep = slideState.HouseHoldIncomeStep ?? {};
  return {
    ...slideState,
    HouseHoldIncomeStep: {
      ...HouseHoldIncomeStep,
      incomeOptions,
    },
  };
};
