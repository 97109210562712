import React, { memo } from "react";
import index from ".";
import { Stack } from "@mui/material";

interface CDictionary {
  [index: string]: React.FC;
}

interface IDictionary {
  [index: string]: CDictionary;
}

export const PreFetchImages = memo(() => {
  const imageTypes = [
    "basementFinished",
    "atticFinish",
    "heatingEquipmentType",
    "coolingEquipmentType",
    "exteriorWalls",
    "interiorWalls",
    "windowType",
  ] as string[];
  const imageIndex = index as unknown as IDictionary;
  const Images: React.FC[] = [];
  imageTypes.forEach((key: keyof typeof imageIndex) => {
    const assets = Object.keys(imageIndex[key as keyof typeof imageIndex]);
    assets.forEach((assetKey) => {
      const asset = imageIndex[key][assetKey];
      Images.push(asset);
    });
  });
  return (
    <Stack sx={{ display: "none" }}>
      {Images.map((Image) => {
        return <Image />;
      })}
    </Stack>
  );
});

export default PreFetchImages;
