import React, { useEffect } from "react";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab, { tabClasses } from "@mui/material/Tab";
import { Collapse, Button, Stack, styled, useTheme } from "@mui/material";
import type { StackProps, SxProps } from "@mui/material";

import type { CSSProperties, ReactNode } from "react";
import { SlideSchema } from "./slides";

type asyncFunc = (arg1: unknown) => Promise<void>;

export interface WizardStepConfig {
  Component: React.FC;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Schema: any;
  hideFooter?: boolean;
  hideHeader?: boolean;
  backgroundImage?: string;
  callout?: ReactNode;
  styles?: {
    components?: CSSProperties;
    title?: CSSProperties;
  };
  onSubmit?: asyncFunc;
}

export interface WizardPageConfig {
  key: string;
  slide?: SlideSchema;
  sectionLabel?: string;
  stepLabel?: string;
  pageNumber?: number;
  slideIndex?: number;
}
export interface WizardStep {
  label: string;
  disabled?: boolean;
  noNavLink?: boolean;
  pages: WizardPageConfig[];
}
export interface WizardSection {
  label: string;
  disabled?: boolean;
  steps: WizardStep[];
}

interface PageStepProps {
  section: WizardSection;
  active: boolean;
  activeStepLabel: string;
  onChange: (slideIndex: number) => void;
  completed: boolean;
}

// @TODO Pull the label from the language file
const navTree = [
  {
    label: "Your Home",
    steps: [
      {
        label: "Getting Started",
        noNavLink: true,
        pages: [
          {
            key: "AddressStep",
            noNavLink: true,
          },
          {
            key: "GettingStartedStep",
            noNavLink: true,
          },
        ],
      },
      {
        label: "Overview",
        pages: [
          {
            key: "HomeConfirmationStep",
          },
          { key: "FullNameStep" },
          { key: "HomeProfileStep" },
          {
            key: "FloorSizeStep",
          },
          {
            key: "BasementTypeStep",
          },
          {
            key: "BasementFinishedStep",
          },
          {
            key: "AtticTypeStep",
          },
          {
            key: "AtticFinishedStep",
          },
          { key: "GarageStep" },
        ],
      },
      {
        label: "Floor Plan",
        pages: [
          { key: "FloorPlanStep" },
          {
            key: "RoomsConfigurationStep",
          },
        ],
      },
      {
        label: "Current System",
        pages: [
          {
            key: "HeatingEquipmentStep",
          },
          { key: "FuelSourceStep" },
          { key: "HeatRoomsStep" },
          {
            key: "AirConditioningStep",
          },
          {
            key: "AirConditioningRoomsStep",
          },
        ],
      },
      {
        label: "Weatherization",
        pages: [
          { key: "MaterialsSteps" },
          { key: "WindowsStep" },
          { key: "UpgradeStep" },
          { key: "IssuesStep" },
        ],
      },
    ],
  },
  {
    label: "Your Goals",
    steps: [
      {
        label: "Preferences",
        pages: [{ key: "TemperatureStep" }],
      },
      {
        label: "Problem Areas",
        pages: [
          {
            key: "DraftyStuffyStep",
          },
          {
            key: "HomeQualityStep",
          },
        ],
      },
      {
        label: "Priorities",
        pages: [
          {
            key: "WhyAreYouShoppingStep",
          },
          {
            key: "WhatsImportantToYouStep",
          },
        ],
      },
    ],
  },
  {
    label: "Rebates & Incentives",
    steps: [
      {
        label: "Almost Done",
        noNavLink: true,
        pages: [
          {
            key: "AlmostDoneStep",
          },
        ],
      },
      {
        label: "Rent or Own",
        pages: [
          {
            key: "OwnOrRentStep",
          },
        ],
      },
      {
        label: "Residents",
        pages: [
          {
            key: "PeopleLiveInHomeStep",
          },
        ],
      },
      {
        label: "Income",
        pages: [
          {
            key: "HouseHoldIncomeStep",
          },
        ],
      },
    ],
  },
  {
    label: "Finalize",
    steps: [
      {
        label: "Confirm",
        pages: [
          {
            key: "CommunicationPreferencesStep",
          },
        ],
      },
    ],
  },
];

let x = 0;
const STEPS: WizardPageConfig[] = navTree.reduce<WizardPageConfig[]>((a, v) => {
  v.steps.forEach((s) => {
    s.pages.forEach((p: WizardPageConfig, pageNumber: number) => {
      p.sectionLabel = v.label;
      p.stepLabel = s.label;
      p.pageNumber = pageNumber;
      p.slideIndex = x;
      a.push(p);
      x += 1;
    });
  });
  return a;
}, []);

export const TabItem = styled(Tab)(({ theme }) => ({
  textTransform: "initial",
  minWidth: 0,
  fontWeight: "normal",
  letterSpacing: 0.5,
  color: "#fff",
  borderRadius: "8px",
  [`&.${tabClasses.selected}`]: {
    color: "yellow",
  },
  [theme.breakpoints.up("md")]: {
    minWidth: 0,
  },
}));

const ProgressBarContainerStyled = styled(Stack)(({ theme }) => ({
  padding: "0px",
  paddingTop: "10px",
  paddingBottom: "10px",
  background: theme.palette.primary.light,
  width: "100%",
  color: "#fff",
  justifyContent: "flex-start",
}));

export const ProgressBarContainer = (props: StackProps) => (
  <ProgressBarContainerStyled direction="row" spacing={1} {...props} />
);

export interface StepTabsProps {
  activeStepLabel: string;
  steps: WizardStep[];
  onChange: (slideIndex: number) => void;
}

export const StepTabs: React.FC<StepTabsProps> = ({
  activeStepLabel,
  steps,
  onChange,
}) => {
  const handleClick = (label: string) => {
    const clickedLabel = steps.find((s) => s.label === label);
    if (clickedLabel && clickedLabel.pages.length > 0) {
      onChange(clickedLabel.pages[0].slideIndex!);
    }
  };

  return (
    <Tabs
      value={activeStepLabel}
      onChange={(_e, label: string) => handleClick(label)}
      TabIndicatorProps={{ className: "hidden sm:block" }}
      classes={{
        flexContainer: "flex-wrap sm:flex-nowrap",
      }}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        width: "auto",
        borderRadius: "35px",
        background: "rgba(255, 255, 255, .2)", //TODO: move to theme
        [`& .${tabsClasses.indicator}`]: {
          height: "100%",
          borderRadius: "8px",
          backgroundColor: "rgba(255, 255, 255, .2)", //TODO: move to theme
        },
      }}
    >
      {steps
        .filter((step) => !step.noNavLink)
        .map((step) => (
          <TabItem
            disabled={step.disabled}
            sx={{
              "&.Mui-disabled": {
                color: "primary.contrastText",
              },
            }}
            key={step.label}
            label={step.label}
            value={step.label}
          />
        ))}
    </Tabs>
  );
};

export const PageStep: React.FC<PageStepProps> = ({
  section,
  active,
  activeStepLabel,
  completed,
  onChange,
}) => {
  const theme = useTheme();

  const buttonStyles = {
    active: {
      color: "primary.contrastText",
      whiteSpace: "nowrap",
      fontWeight: "bold",
      textTransform: "none",
      borderRadius: 8,
    },
    completed: {
      color: "primary.contrastText",
      whiteSpace: "nowrap",
      fontWeight: "bold",
      backgroundColor: "rgba(255, 255, 255, .2)", //TODO: move to theme
      textTransform: "none",
      borderRadius: 8,
    },
    default: {
      color: "primary.contrastText",
      whiteSpace: "nowrap",
      border: `1px dashed ${theme.palette.primary.contrastText}`,
      textTransform: "none",
      borderRadius: "30px",
    },
  } as const;

  const style = completed ? "completed" : active ? "active" : "default";

  const handleClick = () => {
    if (section.steps[0]?.pages[0]) {
      onChange(section.steps[0].pages[0].slideIndex!);
    }
  };

  return (
    <Stack key={section.label} direction="row" spacing={1}>
      <Button
        sx={{
          ...buttonStyles[style],
          "&.Mui-disabled": {
            color: "primary.contrastText",
          },
        }}
        disabled={section.disabled}
        onClick={handleClick}
      >
        {section.label}
        {active && ":"}
      </Button>
      <Collapse in={active} orientation="horizontal">
        {active && (
          <StepTabs
            steps={section.steps}
            activeStepLabel={activeStepLabel}
            onChange={onChange}
          />
        )}
      </Collapse>
    </Stack>
  );
};

export interface ProgressBarProps {
  slide: SlideSchema;
  slideIndex: number;
  onChange: (slideIndex: number) => void;
  containerProps?: SxProps;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  // slide,
  slideIndex,
  onChange,
  containerProps = {
    position: "fixed",
    height: "initial",
    overflowX: "auto",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: [
      "flex-start",
      "flex-start",
      "center",
      // "center",
      // "center",
    ],
    paddingX: "10px",
    backgroundColor: "primary.light",
    zIndex: 1,
  },
}) => {
  const handleOnStepChange = (slideIndex: number) => {
    onChange(slideIndex);
  };

  useEffect(() => {
    let stepIdx = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navTree.forEach((section: any) => {
      section.disabled = stepIdx > slideIndex;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      section.steps.forEach((step: any) => {
        step.disabled = stepIdx > slideIndex;
        step.pages.forEach(() => {
          stepIdx += 1;
        });
      });
    });
  }, [slideIndex]);

  const currentStep = STEPS[slideIndex];

  return (
    <ProgressBarContainer sx={containerProps}>
      {navTree.map((section) => {
        return (
          <PageStep
            key={section.label}
            section={section}
            completed={false}
            active={section.label === currentStep.sectionLabel}
            activeStepLabel={currentStep.stepLabel!}
            onChange={handleOnStepChange}
          />
        );
      })}
    </ProgressBarContainer>
  );
};

export default ProgressBar;
