import { Title, Description } from "./Components";

import {
  schema as AddressStepSchema,
  uiSchema as AddressStepUiSchema,
} from "./AddressStep/schema";

import {
  Title as AddressStepTitle,
  Header as AddressStepHeader,
} from "./AddressStep/Components";

import { background as AddressStepBackground } from "./AddressStep/functions";

import {
  schema as GettingStartedStepSchema,
  uiSchema as GettingStartedStepUiSchema,
} from "./GettingStartedStep/schema";

import // Title as GettingStartedStepTitle,
// Header as GettingStartedHeader,
"./GettingStartedStep/Components";

import {
  visible as GettingStartedStepVisible,
  background as GettingStartedStepBackground,
} from "./GettingStartedStep/functions";

import {
  schema as FullNameStepSchema,
  uiSchema as FullNameStepUiSchema,
} from "./FullNameStep/schema";

import { onNext as FullNameStepOnNext } from "./FullNameStep/functions";

import {
  schema as HomeConfirmationStepSchema,
  uiSchema as HomeConfirmationStepUiSchema,
} from "./HomeConfirmationStep/schema";

// import { Header as HomeConfirmationStepHeader } from "./HomeConfirmationStep/Components";

import {
  schema as HomeProfileStepSchema,
  uiSchema as HomeProfileStepUiSchema,
} from "./HomeProfileStep/schema";

import { Header as HomeProfileStepHeader } from "./HomeProfileStep/Components";

import {
  schema as FloorSizeStepSchema,
  uiSchema as FloorSizeStepUiSchema,
} from "./FloorSizeStep/schema";

import { visible as FloorSizeStepVisible } from "./FloorSizeStep/functions";

import {
  schema as BasementTypeStepSchema,
  uiSchema as BasementTypeStepUiSchema,
} from "./BasementTypeStep/schema";

import { visible as BasementTypeStepVisible } from "./BasementTypeStep/functions";

import {
  schema as BasementFinishedStepSchema,
  uiSchema as BasementFinishedStepUiSchema,
} from "./BasementFinishedStep/schema";

import { visible as BasementFinishedStepVisible } from "./BasementFinishedStep/functions";

import {
  schema as AtticTypeStepSchema,
  uiSchema as AtticTypeStepUiSchema,
} from "./AtticTypeStep/schema";

import { visible as AtticTypeStepVisible } from "./AtticTypeStep/functions";

import {
  schema as AtticFinishedStepSchema,
  uiSchema as AtticFinishedStepUiSchema,
} from "./AtticFinishedStep/schema";

import { visible as AtticFinishedStepVisible } from "./AtticFinishedStep/functions";

import {
  schema as GarageTypeStepSchema,
  uiSchema as GarageTypeStepUiSchema,
} from "./GarageTypeStep/schema";

import { visible as GarageTypeStepVisible } from "./GarageTypeStep/functions";

import {
  schema as FloorPlanStepSchema,
  uiSchema as FloorPlanStepUiSchema,
} from "./FloorPlanStep/schema";

import { Header as FloorPlanStepHeader } from "./FloorPlanStep/Components";

import {
  schema as RoomsConfigurationStepSchema,
  uiSchema as RoomsConfigurationStepUiSchema,
} from "./RoomsConfigurationStep/schema";

import {
  schema as HeatingEquipmentStepSchema,
  uiSchema as HeatingEquipmentStepUiSchema,
} from "./HeatingEquipmentStep/schema";

import {
  schema as FuelSourceStepSchema,
  uiSchema as FuelSourceStepUiSchema,
} from "./FuelSourceStep/schema";

import {
  schema as HeatRoomsStepSchema,
  uiSchema as HeatRoomsStepUiSchema,
} from "./HeatRoomsStep/schema";

import {
  schema as AirConditioningStepSchema,
  uiSchema as AirConditioningStepUiSchema,
} from "./AirConditioningStep/schema";

import {
  schema as AirConditioningRoomsStepSchema,
  uiSchema as AirConditioningRoomsStepUiSchema,
} from "./AirConditioningRoomsStep/schema";

import {
  schema as MaterialsStepsSchema,
  uiSchema as MaterialsStepsUiSchema,
} from "./MaterialsStep/schema";

import {
  schema as WindowsStepSchema,
  uiSchema as WindowsStepUiSchema,
} from "./WindowsStep/schema";

import {
  schema as UpgradesStepSchema,
  uiSchema as UpgradesStepUiSchema,
} from "./UpgradesStep/schema";

import {
  schema as IssuesStepSchema,
  uiSchema as IssuesStepUiSchema,
} from "./IssuesStep/schema";

import {
  schema as TemperatureStepSchema,
  uiSchema as TemperatureStepUiSchema,
} from "./TemperatureStep/schema";

import {
  schema as DraftyStuffyStepSchema,
  uiSchema as DraftyStuffyStepUiSchema,
} from "./DraftyStuffyStep/schema";

import {
  schema as HomeQualityStepSchema,
  uiSchema as HomeQualityStepUiSchema,
} from "./HomeQualityStep/schema";

import {
  schema as WhyAreYouShoppingStepSchema,
  uiSchema as WhyAreYouShoppingStepUiSchema,
} from "./WhyAreYouShoppingStep/schema";

import {
  schema as AlmostDoneStepSchema,
  uiSchema as AlmostDoneStepUiSchema,
} from "./AlmostDoneStep/schema";

import {
  Title as AlmostDoneStepTitle,
  Description as AlmostDoneStepDescription,
  Footer as AlmostDoneStepFooter,
} from "./AlmostDoneStep/Components";

import { background as AlmostDoneStepBackground } from "./AlmostDoneStep/functions";

import {
  schema as WhatsImportantToYouStepSchema,
  uiSchema as WhatsImportantToYouStepUiSchema,
} from "./WhatsImportantToYouStep/schema";

import {
  schema as OwnOrRentStepSchema,
  uiSchema as OwnOrRentStepUiSchema,
} from "./OwnOrRentStep/schema";

import {
  schema as PeopleLiveInHomeStepSchema,
  uiSchema as PeopleLiveInHomeStepUiSchema,
} from "./PeopleLiveInHomeStep/schema";

import {
  schema as HouseHoldIncomeStepSchema,
  uiSchema as HouseHoldIncomeStepUiSchema,
} from "./HouseHoldIncomeStep/schema";

import { fetchOnLoad as HouseHoldIncomeStepFetchOnLoad } from "./HouseHoldIncomeStep/functions";

import {
  schema as CommunicationPreferencesStepSchema,
  uiSchema as CommunicationPreferencesStepUiSchema,
} from "./CommunicationPreferencesStep/schema";

import { visible as CommunicationPreferencesStepVisible } from "./CommunicationPreferencesStep/functions";

export default {
  AddressStep: {
    key: "AddressStep",
    Title: AddressStepTitle,
    Description,
    schema: AddressStepSchema,
    uiSchema: AddressStepUiSchema,
    Header: AddressStepHeader,
    background: AddressStepBackground,
    progress: false,
    nextTitle: "",
    dark: true,
    customSubmit: true,
  },
  GettingStartedStep: {
    key: "GettingStartedStep",
    // Title,
    // Description,
    schema: GettingStartedStepSchema,
    uiSchema: GettingStartedStepUiSchema,
    background: GettingStartedStepBackground,
    visible: GettingStartedStepVisible,
    dark: true,
  },
  FullNameStep: {
    key: "FullNameStep",
    Title,
    Description,
    schema: FullNameStepSchema,
    uiSchema: FullNameStepUiSchema,
    onNext: FullNameStepOnNext,
    animation: false,
  },
  HomeConfirmationStep: {
    key: "HomeConfirmationStep",
    Title,
    Description,
    schema: HomeConfirmationStepSchema,
    uiSchema: HomeConfirmationStepUiSchema,
    nextTitle: "Yes",
    backTitle: "No",
  },
  HomeProfileStep: {
    key: "HomeProfileStep",
    Title,
    Description,
    schema: HomeProfileStepSchema,
    uiSchema: HomeProfileStepUiSchema,
    Header: HomeProfileStepHeader,
  },
  FloorSizeStep: {
    key: "FloorSizeStep",
    Title,
    Description,
    schema: FloorSizeStepSchema,
    uiSchema: FloorSizeStepUiSchema,
    visible: FloorSizeStepVisible,
    canSkip: true,
  },
  BasementTypeStep: {
    key: "BasementTypeStep",
    Title,
    Description,
    schema: BasementTypeStepSchema,
    uiSchema: BasementTypeStepUiSchema,
    canSkip: true,
    visible: BasementTypeStepVisible,
  },
  BasementFinishedStep: {
    key: "BasementFinishedStep",
    Title,
    Description,
    schema: BasementFinishedStepSchema,
    uiSchema: BasementFinishedStepUiSchema,
    canSkip: true,
    visible: BasementFinishedStepVisible,
  },
  AtticTypeStep: {
    key: "AtticTypeStep",
    Title,
    Description,
    schema: AtticTypeStepSchema,
    uiSchema: AtticTypeStepUiSchema,
    canSkip: true,
    visible: AtticTypeStepVisible,
  },
  AtticFinishedStep: {
    key: "AtticFinishedStep",
    Title,
    Description,
    schema: AtticFinishedStepSchema,
    uiSchema: AtticFinishedStepUiSchema,
    canSkip: true,
    visible: AtticFinishedStepVisible,
  },
  GarageStep: {
    key: "GarageStep",
    Title,
    Description,
    schema: GarageTypeStepSchema,
    uiSchema: GarageTypeStepUiSchema,
    canSkip: true,
    visible: GarageTypeStepVisible,
  },
  FloorPlanStep: {
    key: "FloorPlanStep",
    Title,
    Description,
    schema: FloorPlanStepSchema,
    uiSchema: FloorPlanStepUiSchema,
    Header: FloorPlanStepHeader,
  },
  RoomsConfigurationStep: {
    key: "RoomsConfigurationStep",
    Title,
    Description,
    schema: RoomsConfigurationStepSchema,
    uiSchema: RoomsConfigurationStepUiSchema,
    customNavigation: true,
  },
  HeatingEquipmentStep: {
    key: "HeatingEquipmentStep",
    Title,
    Description,
    schema: HeatingEquipmentStepSchema,
    uiSchema: HeatingEquipmentStepUiSchema,
  },
  FuelSourceStep: {
    key: "FuelSourceStep",
    Title,
    Description,
    schema: FuelSourceStepSchema,
    uiSchema: FuelSourceStepUiSchema,
  },
  HeatRoomsStep: {
    key: "HeatRoomsStep",
    Title,
    Description,
    schema: HeatRoomsStepSchema,
    uiSchema: HeatRoomsStepUiSchema,
  },
  AirConditioningStep: {
    key: "AirConditioningStep",
    Title,
    Description,
    schema: AirConditioningStepSchema,
    uiSchema: AirConditioningStepUiSchema,
  },
  AirConditioningRoomsStep: {
    key: "AirConditioningRoomsStep",
    Title,
    Description,
    schema: AirConditioningRoomsStepSchema,
    uiSchema: AirConditioningRoomsStepUiSchema,
  },
  MaterialsSteps: {
    key: "MaterialsSteps",
    Title,
    Description,
    schema: MaterialsStepsSchema,
    uiSchema: MaterialsStepsUiSchema,
  },
  WindowsStep: {
    key: "WindowsStep",
    Title,
    Description,
    schema: WindowsStepSchema,
    uiSchema: WindowsStepUiSchema,
  },
  UpgradeStep: {
    key: "UpgradeStep",
    Title,
    Description,
    schema: UpgradesStepSchema,
    uiSchema: UpgradesStepUiSchema,
  },
  IssuesStep: {
    key: "IssuesStep",
    Title,
    Description,
    schema: IssuesStepSchema,
    uiSchema: IssuesStepUiSchema,
  },
  TemperatureStep: {
    key: "TemperatureStep",
    Title,
    Description,
    schema: TemperatureStepSchema,
    uiSchema: TemperatureStepUiSchema,
  },
  DraftyStuffyStep: {
    key: "DraftyStuffyStep",
    Title,
    Description,
    schema: DraftyStuffyStepSchema,
    uiSchema: DraftyStuffyStepUiSchema,
    canSkip: true,
  },
  HomeQualityStep: {
    key: "HomeQualityStep",
    Title,
    Description,
    schema: HomeQualityStepSchema,
    uiSchema: HomeQualityStepUiSchema,
    canSkip: true,
  },
  WhyAreYouShoppingStep: {
    key: "WhyAreYouShoppingStep",
    Title,
    Description,
    // Callout: WhyAreYouShoppingStepCallout,
    schema: WhyAreYouShoppingStepSchema,
    uiSchema: WhyAreYouShoppingStepUiSchema,
    canSkip: true,
  },
  WhatsImportantToYouStep: {
    key: "WhatsImportantToYouStep",
    Title,
    Description,
    schema: WhatsImportantToYouStepSchema,
    uiSchema: WhatsImportantToYouStepUiSchema,
    canSkip: true,
  },
  AlmostDone: {
    key: "AlmostDone",
    Title: AlmostDoneStepTitle,
    Description: AlmostDoneStepDescription,
    Footer: AlmostDoneStepFooter,
    schema: AlmostDoneStepSchema,
    uiSchema: AlmostDoneStepUiSchema,
    background: AlmostDoneStepBackground,
    customNavigation: true,
    dark: true,
  },
  OwnOrRentStep: {
    key: "OwnOrRentStep",
    Title,
    Description,
    schema: OwnOrRentStepSchema,
    uiSchema: OwnOrRentStepUiSchema,
    canSkip: true,
  },
  PeopleLiveInHomeStep: {
    key: "PeopleLiveInHomeStep",
    Title,
    Description,
    schema: PeopleLiveInHomeStepSchema,
    uiSchema: PeopleLiveInHomeStepUiSchema,
    canSkip: true,
  },
  HouseHoldIncomeStep: {
    key: "HouseHoldIncomeStep",
    Title,
    Description,
    schema: HouseHoldIncomeStepSchema,
    uiSchema: HouseHoldIncomeStepUiSchema,
    fetchOnLoad: HouseHoldIncomeStepFetchOnLoad,
    canSkip: true,
  },
  CommunicationPreferencesStep: {
    key: "CommunicationPreferencesStep",
    Title,
    Description,
    schema: CommunicationPreferencesStepSchema,
    uiSchema: CommunicationPreferencesStepUiSchema,
    visible: CommunicationPreferencesStepVisible,
  },
  // FinalStep: {
  //   key: "FinalStep",
  //   Title,
  //   Description,
  //   schema: FinalStepSchema,
  //   uiSchema: FinalStepUiSchema,
  // },
};
